$(document).ready(function(){
  $('.header-nav__btn').on('click', function(){
			$(this).toggleClass('header-nav__btn-active');
			$('.header-nav').toggleClass('header-nav__active');
		});
		$('.magazine-nav__btn').on('click', function(){
			$(this).toggleClass('magazine-nav__btn-active');
			$('.magazine-nav').toggleClass('magazine-nav__active');
		});
});
