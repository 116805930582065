$(document).ready(function(){
  $(".owl-carousel").owlCarousel({
  	// items: 3,
  	loop: false,
  	dots: true,
  	nav: true,
  	responsiveClass: true,
  	responsive:{
      320:{
        items: 1
      },
  		768:{
  			items: 2
  		},
  		992:{
  			items: 3
  		}
  	}
  });
});